import { Type } from '@angular/core';
import { Route } from '@angular/router';

import { ApplicationFeatures, getFeatureCanMatchGuard, nonProductionCanMatchGuard, TldUtils } from '@portal/core/utils';

import { ForbiddenPageComponent } from './forbidden-page/forbidden-page.component';
import { NotFoundPageComponent } from './not-found-page/not-found-page.component';

const subdomainRoutes: Route[] = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'contacts',
    },
    {
        path: 'contacts',
        canMatch: [getFeatureCanMatchGuard(ApplicationFeatures.CONTACTS_APPLICATION)],
        loadChildren: async () => (await import('@portal/contacts/routes')).routes,
    },
    {
        path: 'calendar',
        canMatch: [getFeatureCanMatchGuard(ApplicationFeatures.CALENDAR_APPLICATION)],
        loadChildren: async () => (await import('@portal/calendar/routes')).routes,
    },
    {
        path: 'case-files',
        canMatch: [getFeatureCanMatchGuard(ApplicationFeatures.CASE_FILE_APPLICATION)],
        loadChildren: async () => (await import('@portal/case-files/routes')).routes,
    },
    {
        path: 'accounting',
        canMatch: [getFeatureCanMatchGuard(ApplicationFeatures.ACCOUNTING_APPLICATION)],
        loadChildren: async () => (await import('@portal/accounting/routes')).routes,
    },
    {
        path: 'admin-console',
        canMatch: [getFeatureCanMatchGuard(ApplicationFeatures.ADMIN_CONSOLE_APPLICATION)],
        loadChildren: async () => (await import('@portal/admin-console/routes')).routes,
    },
    {
        path: 'team',
        canMatch: [getFeatureCanMatchGuard(ApplicationFeatures.TEAM_APPLICATION)],
        loadChildren: async () => (await import('@portal/team/routes')).routes,
    },
    {
        path: 'user',
        canMatch: [getFeatureCanMatchGuard(ApplicationFeatures.USER_APPLICATION)],
        loadChildren: async () => (await import('@portal/user/routes')).routes,
    },
    {
        path: 'admin',
        canMatch: [nonProductionCanMatchGuard],
        loadChildren: async () => (await import('@portal/tenant-admin/routes')).routes,
    },
] satisfies Route[];

const domainRoutes: Route[] = [
    {
        path: 'tenant-nexus',
        canMatch: [nonProductionCanMatchGuard],
        loadChildren: async () => (await import('@portal/tenant-nexus/routes')).routes,
    },
] satisfies Route[];

const devRoute: Route = {
    path: 'dev',
    canMatch: [nonProductionCanMatchGuard],
    // eslint-disable-next-line @nx/enforce-module-boundaries
    loadComponent: async () => (await import('@portal/shared/ui')).SandboxComponent as Type<unknown>,
};

export function getAppRoutes(isDevMode: boolean): Route[] {
    return [
        ...(isDevMode ? [devRoute] : []),
        ...(isDevMode && TldUtils.hasDomain() && !TldUtils.hasSubdomain() ? domainRoutes : []),
        ...(TldUtils.hasSubdomain() ? subdomainRoutes : []),
        {
            path: '403',
            component: ForbiddenPageComponent,
        },
        {
            path: '404',
            component: NotFoundPageComponent,
        },
        {
            path: '**',
            redirectTo: '404',
        },
    ] satisfies Route[];
}
